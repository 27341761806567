import type { RequestMiddleware, Variables } from "graphql-request"
import { GraphQLClient } from "graphql-request"

import { getAnonymousId } from "@bounce/analytics"
import { addFeatureFlagHeaders } from "@bounce/auth/src/graphql-request"

import { config } from "../../config"

const requestMiddleware: RequestMiddleware = async originalRequest => {
  const middlewares: RequestMiddleware[] = [addFeatureFlagHeaders(getAnonymousId())]

  return middlewares.reduce(async (request, middleware) => middleware(await request), Promise.resolve(originalRequest))
}

const client = new GraphQLClient(config.apis.bounce, {
  fetch,
  requestMiddleware,
})

export const bounceClient = <TData, TVariables extends Variables | undefined = Variables>(
  query: string,
  variables?: TVariables,
): (() => Promise<TData>) => {
  const minifiedQuery = query.replace(/\s+/g, " ").trim()

  return async () =>
    client.request({
      document: minifiedQuery,
      variables,
    })
}
