import type { RequestMiddleware, ResponseMiddleware } from "graphql-request"

import type { Nullable } from "@bounce/util"

import type { AuthToken } from "./authToken"
import { inMemoryAuthToken, setAuthToken, isTokenExpired } from "./authToken"
import { logout } from "./helpers/logout"

const INVALID_TOKEN = "unauthorized"

type AuthMiddlewareArgs = {
  domain?: string | null
  refreshToken: (refreshTokenValue: string) => Promise<Nullable<AuthToken>>
}

const addOperationNameToSearchParams = (): RequestMiddleware => {
  return async request => {
    if (request.operationName === undefined) return request

    const url = new URL(request.url)
    url.searchParams.append("operationName", request.operationName)

    return {
      ...request,
      url: url.toString(),
    }
  }
}

// Refetch/Add the authorization token
const authMiddleware = ({ domain, refreshToken }: AuthMiddlewareArgs): RequestMiddleware => {
  return async request => {
    const addTokenToRequest = (token: string) => ({
      ...request,
      headers: {
        ...request.headers,
        authorization: `Bearer ${token}`,
      },
    })

    if (
      !!inMemoryAuthToken?.refreshToken &&
      isTokenExpired(inMemoryAuthToken) &&
      request.operationName !== "RefreshToken"
    ) {
      const newToken = await refreshToken(inMemoryAuthToken.refreshToken)

      if (newToken) {
        await setAuthToken(newToken, domain)

        return addTokenToRequest(newToken.accessToken)
      }
    } else if (inMemoryAuthToken?.accessToken) {
      return addTokenToRequest(inMemoryAuthToken.accessToken)
    }

    return request
  }
}

// Logout users with invalid token
const logoutMiddleware: ResponseMiddleware = response => {
  if (response instanceof Error) return

  // Remove invalid token
  const hasToken = !!inMemoryAuthToken
  const hasInvalidTokenMessage = !!response.errors?.find(e => e.message === INVALID_TOKEN)

  if (hasToken && hasInvalidTokenMessage) {
    void logout()
  }
}

type AddAppSpecificHeadersArgs = {
  platform: string
  version: string
  appName: string
}

const addAppSpecificHeaders =
  ({ platform, version, appName }: AddAppSpecificHeadersArgs): RequestMiddleware =>
  request => ({
    ...request,
    headers: {
      ...request.headers,
      "x-bounce-platform": platform,
      "x-bounce-app-version": version,
      "x-bounce-app-name": appName,
    },
  })

const addFeatureFlagHeaders =
  (anonymousId: string): RequestMiddleware =>
  request => ({
    ...request,
    headers: {
      ...request.headers,
      "x-bounce-feature-flag-user-id": anonymousId,
    },
  })

const addAccountIdHeader =
  (accountId: string): RequestMiddleware =>
  request => ({
    ...request,
    headers: {
      ...request.headers,
      "x-bounce-account": accountId,
    },
  })

const addSegmentIdHeaders =
  (segmentAnonymousId: string): RequestMiddleware =>
  request => ({
    ...request,
    headers: {
      ...request.headers,
      "x-bounce-segment-anonymous-id": segmentAnonymousId,
    },
  })

export {
  addOperationNameToSearchParams,
  authMiddleware,
  logoutMiddleware,
  addAppSpecificHeaders,
  addFeatureFlagHeaders,
  addAccountIdHeader,
  addSegmentIdHeaders,
}
